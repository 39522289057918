<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table p-2"
  >
    <h4 class="mb-50 fw-700">
      {{ $t(`retail.${titleTable}Table`) }}
    </h4>
    <b-table
      :items="tableData"
      responsive
      bordered
      :fields="tableColumns"
      class="mb-0"
      show-empty
      :empty-text="$t('noMatchingResult')"
    >
      <template
        v-for="column in tableColumns"
        v-slot:[`head(${column})`]
      >
        <div
          :key="column"
          class="text-dark text-nowrap text-right"
          :class="{ 'text-center': column === 'source' }"
        >
          {{ $t(`retail.columns.${column}`) }}
        </div>
      </template>

      <template #cell(source)="data">
        <div class="text-nowrap text-center">
          <span
            class="h5 mb-0 py-25 px-50"
            :style="resolveBorderColorByAirline(data.item.source, 900)"
          >
            {{ data.item.source }}
          </span>
        </div>
      </template>

      <template #cell(count)="{ item }">
        <div class="font-weight-bolder text-right">
          {{ formatCurrency(item.count || 0) }}
        </div>
      </template>

      <template #cell(totalPayment)="{ item }">
        <div class="font-weight-bolder text-right">
          {{ formatCurrency(item.totalPayment || 0) }}
        </div>
      </template>

      <template #cell(tradeCreditors)="{ item }">
        <div class="font-weight-bolder text-right">
          {{ formatCurrency(item.tradeCreditors || 0) }}
        </div>
      </template>

      <template #cell(receivables)="{ item }">
        <div class="font-weight-bolder text-right">
          {{ formatCurrency(item.receivables || 0) }}
        </div>
      </template>

      <template #cell(profit)="{ item }">
        <div class="font-weight-bolder text-right">
          {{ formatCurrency(item.profit || 0) }}
        </div>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import {
  formatCurrency,
  resolveBorderColorByAirline,
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    titleTable: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const fields = ['source', 'count', 'totalPayment', 'tradeCreditors', 'receivables', 'profit']

    const tableColumns = computed(() => props.titleTable === 'book' ? fields.filter(item => ['source', 'count'].includes(item)) : fields)
    return {
      tableColumns,
      resolveBorderColorByAirline,
      formatCurrency,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
