var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tableData ? _c('b-card', {
    staticClass: "card-company-table p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "mb-50 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t("retail.".concat(_vm.titleTable, "Table"))) + " ")]), _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "items": _vm.tableData,
      "responsive": "",
      "bordered": "",
      "fields": _vm.tableColumns,
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('div', {
            key: column,
            staticClass: "text-dark text-nowrap text-right",
            class: {
              'text-center': column === 'source'
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("retail.columns.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(source)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap text-center"
        }, [_c('span', {
          staticClass: "h5 mb-0 py-25 px-50",
          style: _vm.resolveBorderColorByAirline(data.item.source, 900)
        }, [_vm._v(" " + _vm._s(data.item.source) + " ")])])];
      }
    }, {
      key: "cell(count)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.count || 0)) + " ")])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPayment || 0)) + " ")])];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.tradeCreditors || 0)) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.receivables || 0)) + " ")])];
      }
    }, {
      key: "cell(profit)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "font-weight-bolder text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.profit || 0)) + " ")])];
      }
    }], null, true)
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }